<template>
    <v-dialog v-model="dialog" persistent max-width="750px">
        <v-card>
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>QRコード</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col cols="5" class="px-0">
                        <Qrcode :width="280" :value="qrValue" />
                    </v-col>
                    <v-col cols="7" class="pt-10 pl-0">
                        <div class="font-weight-black">会社情報</div>
                        <v-divider class="mb-3" />
                        <v-simple-table class="mt-5">
                            <col style="width: 130px" />
                            <col style="width: auto" />
                            <tr>
                                <th>会社ID :</th>
                                <td class="pl-5">{{ memberInfo.member_id }}</td>
                            </tr>
                            <tr>
                                <th>会社名:</th>
                                <td class="pl-1">{{ memberInfo.member_name }}</td> 
                            </tr>
                            <tr>
                                <th>事業場名:</th>
                                <td class="pl-1">{{ memberInfo.workplace }}</td> 
                            </tr>

                            <tr>
                                <th>メールアドレス1:</th>
                                <td class="pl-1">{{ memberInfo.mail1 }}</td>
                            </tr>
                            <tr>
                                <th>メールアドレス2:</th>
                                <td class="pl-1">{{ memberInfo.mail2 }}</td>
                            </tr>
                            <tr>
                                <th>QRコードURL:</th>
                                <td class="pl-1">{{ qrValue }}</td>
                            </tr>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="clickPrint" color="warning">
                    PDF
                </v-btn>
                <v-btn @click="dialog = false" color="error">
                    閉じる
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style lang="scss" scoped>
th {
	width:150px;
	/* background-color:lightgray;
	border-top:1px solid #000;
	border-left:1px solid #000; */
	text-align: justify;
	text-align-last: justify;
	text-justify: inter-character;
	padding:5px;
}

td {
	margin-left: 10px;
}
</style>
<script>
import Qrcode from "vue-qrcode";
export default {
    components: { Qrcode },
    data: () => ({
	memberInfo:{},
        dialog: false,
        qrValue: "",

        disposFlg: false,
    }),
    methods: {
        /**
         * ダイアログオープン
         */
        async open(memberInfo) {
            this.memberInfo = memberInfo;
            this.dialog = true;
            if(location.hostname == 'admin.sodai.site'){
                this.qrValue = `https://mobile.sodai.site?id=${memberInfo.member_id}`
            }else{
                this.qrValue = `https://test-mobile.sodai.site?id=${memberInfo.member_id}`
            }
        },
        async clickPrint(){
            var postData ={
               memberId: this.memberInfo.member_id,
               memberName: this.memberInfo.member_name,
               workPlace: this.memberInfo.workplace,
               mail1: this.memberInfo.mail1,
               mail2: this.memberInfo.mail2,
            };
            await this.axios.post('/pdf',postData, {responseType: "blob"})
                .then( response => {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', "QRCode" + ".pdf");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
                
                /*
            .then( response => {
                    console.log(response)
                    // window.open("https://api.sodai.site/pdf-view");
                    const fileURL = window.URL.createObjectURL(new File([response.data], 'qrcode.pdf', {type:'application/pdf'}));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', "Qrcode_" + this.memberInfo.MemberId + ".pdf");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
                */
                
        },
        toBlob: function (base64) {
            var bin = atob(base64.replace(/^.*,/, ''));
            var buffer = new Uint8Array(bin.length);
            for (var i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i);
            }
            // Blobを作成
            try{
                var blob = new Blob([buffer.buffer], {
                    type: 'application/pdf'
                });
            }catch (e){
                return false;
            }
            return blob;
                }
    },
};
</script>