<template>
	<v-container>
		<v-card class="pt-1">
			<div class="text-h4 mx-5 my-3 font-weight-bold">会社一覧</div>
			<v-divider class="mx-3 mt-0 mb-5"></v-divider>
			<v-row dense>
				<v-col cols="12" class="px-15 d-flex">
					<v-subheader> 絞込（会社名）</v-subheader>
					<v-text-field
						outlined
						hide-details=""
						v-model="searchName"
						maxlength="40"
						dense
						@keydown.enter="serchMember"
					/>
					<v-spacer/>
					<v-btn @click="memberAdd" large color="success" class="mr-3">新規登録</v-btn>
				</v-col>
			</v-row>
			<v-simple-table
				dense
				class="px-15 py-2 list-table line mt-1"
				height="500"
				fixed-header
			>
				<col style="width: 50px" />
				<col style="width: 70px" />
				<col style="width: auto" />
				<col style="width: 300px" />
				<col style="width: 200px" />
				<col style="width: 150px" />
				<thead>
					<tr>
						<th class="text-center">ID</th>
						<th class="text-center">状態</th>
						<th>
							<p class="ma-2">会社名</p>
							<v-divider></v-divider>
							<p class="ma-2">事業場名</p>
						</th>
						<th>
							<p class="ma-2">メールアドレス1</p>
							<v-divider></v-divider>
							<p class="ma-2">メールアドレス2</p>
						</th>
						<th class="text-center">備考</th>
						<th class="text-center">処理</th>
					</tr>
				</thead>
				<tbody>
					<tr 
						v-for="(member, index) in memberList"
						:key="index"
						v-bind:class="[index%2?'color-row':'']"
					>
						<td class="text-center">
							{{ member.member_id }}
						</td>
						<td class="text-center">
							{{ member.status == 1? '稼働': '停止' }}
						</td>
						<td class="pa-0">
							<p class="my-1 mx-2">{{ member.member_name }}</p>
							<v-divider></v-divider>
							<p class="my-1 mx-2">{{ member.workplace}}</p>
						</td>
						<td class="pa-0">
							<p class="my-1 mx-2">{{ member.mail1}}</p>
							<v-divider></v-divider>
							<p class="my-1 mx-2">{{ member.mail2 }}</p>
						</td>
						<v-tooltip top v-if="member.Remarks!=''">
							<template v-slot:activator="{ on, attrs }">
								
								<td class="remarks"
									v-bind="attrs"
									v-on="on"
									v-html="member.Remarks2line"
								>
								</td>
							</template>
							<div v-html="member.RemarksHtml"></div>
						</v-tooltip>
						<td v-else>
						</td>
						<td>
							<div class="d-flex">
								<v-btn class="ma-2" color="warning" @click="openDlgQr(member)">QR</v-btn>
								<v-btn class="ma-2" color="error" @click="memberEdit(member)">編集</v-btn>
							</div>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
			<dlg-qr ref="dlgQr"></dlg-qr>
		</v-card>
	</v-container>
</template>

<style lang="scss">
.bottom-line {
	border-bottom:1px solid #aaa;
}

.list-table{
    table {
      display: table;
      table-layout:collapse;
     border-right:1px solid #aaa;
     border-bottom:1px solid #aaa;
    }
    th {
        background-color:#dae4f8 !important;
        color:#555 !important;
        font-size: 12pt !important;
	height: 50px!important;
	padding-right: 0px !important;
	padding-left: 0px !important;
    }
    tr.rent_status_complete{
      background-color:#BDBDBD;
    }
    tr.rent_status_kaisyu{
      background-color:#FFE0B2;
    }

}
 .list-table.line {
   th{
     border-top:1px solid #aaa;
     border-left:1px solid #aaa;
   }
   td{
     border-top:1px solid #aaa;
     border-left:1px solid #aaa;
   }
 } 

.remarks {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 150px;
}

.color-row {
	background-color:#f0f0f0;
}
</style>

<script>
import DlgQr from '../components/DlgQr.vue';

export default {
	name: 'MemberList',
	components: {
		DlgQr
	},
	data() {
		return {
			memberList: [],	
			searchName: '',
		}
	},
	mounted: function () {
		this.axios.post('search-members').then((res) => {
			this.memberList = res.data.memberList;
			this.memberList.forEach(element => {
				element.RemarksHtml = (element.remarks != null)? element.remarks.replace(/\n/g, '<br/>'):'';
				const arrRemarks = (element.remarks != null)? element.remarks.split('\n'):[];
				element.RemarksHtml = arrRemarks.join('<br/>');
				element.Remarks2line = arrRemarks.slice(0,2).join('<br/>');
			});
		})
		
	},
	methods: {
		memberAdd: function () {
			this.$router.push({ name: 'MemberEdit', params: { mode: 0 }})
		},
		memberEdit: function (memberInfo) {
			this.$router.push({ 
                name: 'MemberEdit', 
                params: { 
                    mode: 1, 
                    memberId: memberInfo.member_id, 
                    status: memberInfo.status, 
                    memberName: memberInfo.member_name, 
                    workPlace: memberInfo.workplace, 
                    mail1: memberInfo.mail1, 
                    mail2: memberInfo.mail2, 
                    remarks: memberInfo.remarks,
					userSubject: memberInfo.user_subject,
					mailTemplate: memberInfo.mail_template,
					}})
		},
		openDlgQr: function (memberInfo) {
			this.$refs.dlgQr.open(memberInfo);
		},
		serchMember: function () {
			const postData = {
				searchName: this.searchName,
			}
			this.axios.post('search-members', postData).then((res)=> {
				this.memberList = res.data.memberList;
				this.memberList.forEach(element => {
					element.RemarksHtml = (element.remarks != null)? element.remarks.replace(/\n/g, '<br/>'):'';
					const arrRemarks = (element.remarks != null)? element.remarks.split('\n'):[];
					element.RemarksHtml = arrRemarks.join('<br/>');
					element.Remarks2line = arrRemarks.slice(0,2).join('<br/>');
				});
			})
		}
	}
}
</script>