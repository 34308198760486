<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-show="isLogin"
    >
      <p class="white--text text-h3">WCJシステム</p> 
      <v-spacer></v-spacer>

      <v-btn 
        outlined
        large
        @click="logout"
      >
        Logout
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
    token: '',
  }),
  computed: {
    isLogin: function () {
      return this.$store.state.token != null && this.$store.state.token != '';
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('setToken', '');
      this.$router.push("/");
    }
  }
};
</script>
