<template>
	<v-container>
		<v-alert
			class="success-alert"
			:value="alert"
			outlined
			type="success"
			text
			elevation="3"
			transition="slide-y-transition"
		>
			{{ alertMsg }}
		</v-alert>
		<v-card class="pt-1">
			<div class="d-flex justify-space-between">
				<div class="text-h4 mx-5 my-3 font-weight-bold">{{ title }}</div>
				<div class="my-auto">
					<v-btn v-if="mode==1" class="my-auto mr-2" color="error" large @click="clickDelete">削除</v-btn>
					<v-btn class="my-auto mr-10" color="warning" large @click="clickBack">戻る</v-btn>
				</div>
			</div>
			<v-divider class="mx-3 mt-0 mb-5"></v-divider>
			<v-row dense>
				<v-col cols="2"></v-col>
				<v-col cols="8">
				<div class="mx-auto">
					<v-simple-table class="pa-15">
						<tr>
							<th>会社ID :</th>
							<td><span class="ml-5">{{ dispMemberId }}</span></td>
						</tr>
						<tr>
							<th>状態 :</th>
							<td>
								<v-radio-group
									v-model="status"
									row
									dense
									class="mx-2"
								>
									<v-radio
										v-for="(st, index) in statusList"
										:key="index"
										:label="st.label"
										:value="st.val"
									></v-radio>
								</v-radio-group>
							</td>
						</tr>
						<tr>
							<th>会社名 :</th>
							<td>
								<v-text-field
									v-model="memberName"
									dense
									outlined
									hide-details=""
									class="my-1 mx-2"
									style="width:500px"
									:error="errMsg.memberName.length>0"
								></v-text-field>
								<span v-show="errMsg.memberName.length>0" class="error-message ml-5">
									{{ errMsg.memberName[0] }}
								</span>
							</td>
						</tr>
						<tr>
							<th>事業場名 :</th>
							<td>
								<v-text-field
									v-model="workPlace"
									dense
									outlined
									hide-details=""
									class="my-1 mx-2"
									style="width:500px"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th>メールアドレス1 :</th>
							<td>
								<v-text-field
									v-model="mail1"
									dense
									outlined
									hide-details=""
									class="my-1 mx-2"
									style="width:500px"
									:error="errMsg.mail1.length>0"
								></v-text-field>
								<span v-show="errMsg.mail1.length>0" class="error-message ml-5">
									{{ errMsg.mail1[0] }}
								</span>
							</td>
						</tr>
						<tr>
							<th>メールアドレス2 :</th>
							<td>
								<v-text-field
									v-model="mail2"
									dense
									outlined
									hide-details=""
									class="my-1 mx-2"
									style="width:500px"
									:error="errMsg.mail2.length>0"
								></v-text-field>
								<span v-show="errMsg.mail2.length>0" class="error-message ml-5">
									{{ errMsg.mail2[0] }}
								</span>
							</td>
						</tr>
						<tr>
							<th>メール件名 :</th>
							<td>
								<v-text-field
									v-model="userSubject"
									dense
									outlined
									hide-details=""
									class="my-1 mx-2"
									style="width:500px"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th style="vertical-align: top!important;">メール設定欄 :</th>
							<td>
								<v-textarea
									v-model="mailTemplate"
									outlined
									class="my-1 mx-2"
									style="width:500px"
								></v-textarea>
							</td>
						</tr>
						<tr>
							<th style="vertical-align:top!important;">備考 :</th>
							<td>
								<v-textarea
									v-model="remarks"
									outlined
									class="my-1 mx-2"
									style="width:500px"
								></v-textarea>
							</td>
						</tr>

					</v-simple-table>
				</div>
				<div>
					<div class="mx-auto" style="width:100px">
						<v-btn x-large class="mb-10" @click="clickSave" color="info">保存</v-btn>
					</div>
				</div>
				</v-col>
				<v-col cols="2"></v-col>
			</v-row>
		</v-card>
		
	</v-container>
</template>

<style scoped>
th {
	width:140px;
	/* background-color:lightgray;
	border-top:1px solid #000;
	border-left:1px solid #000; */
	text-align: justify;
	text-align-last: justify;
	text-justify: inter-character;
}

.error-message {
	color:red;
	font-size:12px;
	margin-top:0px;
}

.success-alert {
	position: absolute;
	background-color: #fff!important;
	bottom: 20px;
	right:30px;
	left:auto;
	z-index: 1;
}
</style>

<script>
export default {
	name: 'MemberEdit',
	data(){
		return{
			mode: 0, // 0:新規 1:編集
			title:'',
			memberId: 0,
			status: 0,
			statusList:[{ label:'開始', val: 1 } , { label:'停止', val: 2 }],
			dispMemberId:'',
			memberName: '',
			workPlace:'',
			mail1: '',
			mail2: '',
			userSubject:'',
			mailTemplate:'',
			remarks:'',
			errMsg: {
				memberName:[],
				mail1:[],
				mail2:[],
			},
			alert:false,
			alertMsg:'',
		}
	},
	mounted: function () {
		console.log(this.errMsg)
		this.mode = this.$route.params.mode;
		this.title = this.mode == 0? '新規登録':'編集';
		if(this.mode===1){
			this.memberId = this.$route.params.memberId;
			this.status = this.$route.params.status;
			this.memberName = this.$route.params.memberName;
			this.workPlace = this.$route.params.workPlace;
			this.mail1 = this.$route.params.mail1??'';
			this.mail2 = this.$route.params.mail2??'';
			this.remarks = this.$route.params.remarks;
			this.userSubject = this.$route.params.userSubject;
			this.mailTemplate = this.$route.params.mailTemplate;
		}
		this.dispMemberId = this.mode == 0? '自動で採番されます':this.memberId;
	},
	methods: {
		clickSave: function () {
			if(this.inputCheck())return

			const postData = {
				mode: this.mode,
				memberId: this.memberId,
				status: this.status,
				memberName: this.memberName,
				workPlace: this.workPlace,
				mail1: this.mail1,
				mail2: this.mail2,
				user_subject: this.userSubject,
				mail_template: this.mailTemplate,
				remarks: this.remarks,
			}
			this.axios.post("update-member", postData).then((res)=> {
				console.log(res);
				this.alertMsg = '保存しました。'
				this.alert = true;
				setTimeout(() => {
					this.alert = false;
				}, 3000)
			})
		},
		clickBack: function () {
			this.$router.back();
		},
		clickDelete: async function () {
			// 確認メッセージ
			this.$swal({
				title: '確認',
				text: '削除してもよろしいですか？',
				allowOutsideClick: false,
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "はい",
				cancelButtonText: "いいえ"
			}).then((result) => {
				if (result.isConfirmed) {
				/**はい */
				this.axios.post('delete-member',{ memberId: this.memberId })
				.then((res) => {
					console.log(res)

					this.alertMsg = '削除しました。'
					this.alert = true;
					setTimeout(() => {
						this.alert = false;
						this.$router.back();
					}, 3000)
				})
				return;
				}
				/**いいえ */
				return;
			})
		},
		inputCheck: function () {
			let errFlg = false;
			this.errMsg.memberName = [];
			this.errMsg.mail1 = [];
			this.errMsg.mail2 = [];

			if(this.memberName==''){
				this.errMsg.memberName.push("会社名を入力してください。");
				errFlg = true;
			}
			if(this.mail1==''){
				this.errMsg.mail1.push("メールアドレスを入力してください。");
				errFlg = true;
			}else if(!this.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)){
				this.errMsg.mail1.push("メールアドレスの形式が違います。");
				errFlg = true;
			}
			if(this.mail2!=''&&!this.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)){
				this.errMsg.mail2.push("メールアドレスの形式が違います。");
				errFlg = true;
			}
			return errFlg;
		}
	}
}
</script>