import Vue from 'vue'
import VueRouter from 'vue-router'
import MemberList from '../views/MemberList.vue'
import MemberEdit from '../views/MemberEdit.vue'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login 
  },
  {
    path: '/memberlist',
    name: 'MemberList',
    component: MemberList 
  },
  {
    path: '/member-edit',
    name: 'MemberEdit',
    component: MemberEdit
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from , next)=>{
  if(to.name ==='Login' && store.state.token != ''){
    next({name: 'MemberList'})
  }else{
    if((to.name !== 'Login'  )&& store.state.token == '') next({name:'Login'})
    else next()

  }
})

export default router
